var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"tag":"div","slim":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('FormInput',{attrs:{"name":"username","title":"Username","type":"text","rules":"required","custom-messages":{
        required: 'Username harus diisi'
      }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"text-sm text-gray-800 dark:text-white"},[_vm._v(" Username ")])]},proxy:true}],null,true),model:{value:(_vm.payload.username),callback:function ($$v) {_vm.$set(_vm.payload, "username", $$v)},expression:"payload.username"}}),_c('br'),_c('div',{staticClass:"relative"},[_c('FormInput',{attrs:{"name":"password","title":"Password","type":_vm.showPassword ? 'text' : 'password',"rules":"required","custom-messages":{
          required: 'Password harus diisi'
        }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"text-sm text-gray-800 dark:text-white"},[_vm._v(" Password ")])]},proxy:true}],null,true),model:{value:(_vm.payload.password),callback:function ($$v) {_vm.$set(_vm.payload, "password", $$v)},expression:"payload.password"}})],1),_c('br'),_c('div',{staticClass:"flex justify-end"},[_c('button',{class:{
          'btn-login-uname-pwd': true,
          'is-error': _vm.hasError(errors),
          'is-disabled': _vm.isPayloadEmpty,
          'is-pending': _vm.isPending
        },attrs:{"type":"submit"}},[(_vm.isPending)?_c('img',{staticClass:"inline-block w-auto object-contain object-center",staticStyle:{"height":"24px"},attrs:{"src":require("@/assets/loading.gif")}}):_c('span',[_vm._v(" Login ")])])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }